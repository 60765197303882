import {
  Flex,
  Box,
  HStack,
  Button,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FaApple, FaGooglePlay } from 'react-icons/fa6';

const DownloadApp = ({ type }) => {
  const router = useRouter();

  const onDownloadApp = () => {
    if (type === 'driver') {
      router.push('/app-driver');
    } else {
      router.push('/app');
    }
  };

  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const [isLargerThan728] = useMediaQuery('(min-width: 728px)');

  return (
    <Flex
      direction="row"
      justifyContent="center"
      alignItems={'center'}
      w="100%"
      position={'relative'}
    >
      <Box
        px={isLargerThan1024 ? '200px' : isLargerThan728 ? '100px' : '50px'}
        py={'60px'}
        w="100%"
        backgroundColor={'blackAlpha.600'}
      >
        <Image
          src="/images/download_driver.jpg"
          alt="background"
          layout="fill"
          objectFit="cover"
          style={{ zIndex: -1 }}
        />
        <Flex
          direction={isLargerThan1024 ? 'row' : 'column'}
          justify={'space-between'}
        >
          <Box>
            <Text m={0} color={'white'} fontSize={'2xl'}>
              Download Now
            </Text>
            <Text
              m={0}
              color={'white'}
              fontSize={isLargerThan1024 ? '5xl' : '3xl'}
              lineHeight={'50px'}
            >
              {type === 'driver'
                ? 'Register in the app'
                : 'iOS and Android devices'}
            </Text>
          </Box>
          <HStack mt={isLargerThan1024 ? 0 : 20}>
            <Button
              name="apple"
              type="button"
              backgroundColor={'white'}
              shadow={'sm'}
              color={'#0b0b0b'}
              _hover={{
                opacity: 0.7,
              }}
              fontWeight={'medium'}
              leftIcon={<FaApple />}
              onClick={() => onDownloadApp()}
            >
              App Store
            </Button>
            <Button
              name="google"
              type="button"
              backgroundColor={'white'}
              shadow={'sm'}
              color={'#0b0b0b'}
              _hover={{
                opacity: 0.7,
              }}
              fontWeight={'medium'}
              leftIcon={<FaGooglePlay />}
              onClick={() => onDownloadApp()}
            >
              Google Play
            </Button>
          </HStack>
        </Flex>
      </Box>
    </Flex>
  );
};

export default DownloadApp;
