import { gql } from "@apollo/client";

export const GET_CO2_SAVINGS = gql`
  query GetTotalCo2Saving {
    getTotalCo2Saving {
      total_miles
      total_co2_saved
      total_green_miles
    }
  }
`;
