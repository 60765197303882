import Head from 'next/head';
import { useContext } from 'react';
import { useRouter } from 'next/router';
import { GlobalSeoContext } from '../../pages/_app';
import { getStrapiMedia } from '../../lib/media';

const Seo = ({ seo }) => {
  const router = useRouter();

  const domain =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : '';
  let path = domain + router.asPath;

  let data: any;

  data = useContext(GlobalSeoContext);

  let siteName = 'Vert';
  let seo_with_defaults: any;

  if (!data) {
    return;
  }

  if (data) {
    seo_with_defaults = {
      ...data,
      ...seo,
    };
  }

  const fullSeo = {
    ...seo_with_defaults,
    // Add title suffix
    metaTitle: `${seo_with_defaults.metaTitle} | ${siteName}`,
    // Get full image URL
    metaImage: getStrapiMedia(seo_with_defaults.metaImage),
    publishedAt: `${seo_with_defaults.publishedAt}`,
    url: path,
    socialType: seo_with_defaults.article ? 'article' : 'website',
    socialTitle: seo_with_defaults.article
      ? seo_with_defaults.metaTitle
      : seo_with_defaults.social[0].title,
    socialDescription: seo_with_defaults.article
      ? seo_with_defaults.metaDescription
      : seo_with_defaults.social[0].description,
    socialImage: getStrapiMedia(seo_with_defaults.shareImage),
  };

  return (
    <Head>
      {/* Google Data */}
      <meta
        name="google-site-verification"
        content="gUoVYc4fOHy0jH5Hp0nQYaEJ-4toNJyi78F-W_YpHOc"
      />
      <link
        rel="google-site-verification"
        href="/google040d1796b3e5cf54.html"
      />
      {/* Structured Data (JSON-LD format) */}
      <script
        key="structured-data"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(fullSeo.structuredData),
        }}
      />
      {/* General SEO */}
      {fullSeo.metaTitle && (
        <>
          <title>{fullSeo.metaTitle}</title>
          <meta name="description" content={fullSeo.metaDescription} />
          <meta name="image" content={fullSeo.metaImage} />
        </>
      )}

      {/* Facebook Open Graph */}
      <meta property="og:type" content={fullSeo.socialType} />
      <meta property="og:title" content={fullSeo.socialTitle} />
      <meta property="og:description" content={fullSeo.socialDescription} />
      <meta property="og:image" content={fullSeo.socialImage} />
      <meta property="og:url" content={fullSeo.url} />
      <meta property="og:site_name" content="Vert" />
      <meta property="og:locale" content="en_GB" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={fullSeo.socialTitle} />
      <meta name="twitter:description" content={fullSeo.socialDescription} />
      <meta name="twitter:image" content={fullSeo.socialImage} />
      <meta name="twitter:site" content="@Vert_LDN" />
      <meta name="twitter:creator" content="@Vert_LDN" />
      <meta property="twitter:image:width" content="1200" />
      <meta property="twitter:image:height" content="1200" />

      {/* Other SEO and metadata */}
      <meta name="viewport" content={fullSeo.metaViewport} />
      <meta name="robots" content={fullSeo.metaRobots} />
      <link rel="canonical" href={fullSeo.url} />

      {/* Articles SEO and metadata */}

      <meta
        name="publish_date"
        property="og:publish_date"
        content={fullSeo.publishedAt}
      />
      <meta property="article:published_time" content={fullSeo.publishedAt} />
    </Head>
  );
};

export default Seo;
